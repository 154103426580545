var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-toolbar",
    [
      _c(
        "v-row",
        {
          staticClass: "text-center",
          attrs: { justify: "center", elevation: "1", outlined: "" },
        },
        [
          _c(
            "v-menu",
            {
              attrs: {
                transition: "scroll-y-transition",
                "offset-y": "",
                "close-on-content-click": "",
              },
              scopedSlots: _vm._u([
                {
                  key: "activator",
                  fn: function ({ on, attrs }) {
                    return [
                      _c(
                        "v-btn",
                        _vm._g(
                          _vm._b(
                            {
                              ref: "dayMenuActivator",
                              staticClass: "my-1 ml-6",
                              attrs: {
                                color: "primary",
                                outlined: "",
                                disabled:
                                  _vm.editMode || _vm.fullEditorEditMode,
                              },
                              on: {
                                keyup: function ($event) {
                                  if (
                                    !$event.type.indexOf("key") &&
                                    _vm._k(
                                      $event.keyCode,
                                      "right",
                                      39,
                                      $event.key,
                                      ["Right", "ArrowRight"]
                                    )
                                  )
                                    return null
                                  if ("button" in $event && $event.button !== 2)
                                    return null
                                  return _vm.focusEditButton()
                                },
                              },
                            },
                            "v-btn",
                            attrs,
                            false
                          ),
                          on
                        ),
                        [_c("span", [_vm._v(_vm._s(_vm.scheduleName))])]
                      ),
                    ]
                  },
                },
              ]),
            },
            [
              _c(
                "perfect-scrollbar",
                { staticStyle: { "max-height": "calc(100vh - 60px)" } },
                [
                  _c(
                    "v-list",
                    { ref: "dayMenuList" },
                    [
                      _c(
                        "v-list-item",
                        { on: { click: _vm.addAlternateDayModalShow } },
                        [
                          _c(
                            "v-list-item-title",
                            { staticClass: "drop-down-list" },
                            [_vm._v(_vm._s(_vm.$t("addAlternateDayLabel")))]
                          ),
                        ],
                        1
                      ),
                      _vm._l(_vm.specialDayItems, function (item, i) {
                        return _c(
                          "v-list-item",
                          {
                            key: i,
                            on: {
                              click: function ($event) {
                                return _vm.switchToRegularOrAlternateSchedule(
                                  item
                                )
                              },
                            },
                          },
                          [
                            _c(
                              "v-list-item-title",
                              { staticClass: "drop-down-list" },
                              [
                                _c("span", [_vm._v(_vm._s(item.text))]),
                                item.value !== 0
                                  ? _c(
                                      "v-btn",
                                      {
                                        ref: "editButton" + i,
                                        refInFor: true,
                                        staticClass: "ml-3 menu-subitem",
                                        attrs: {
                                          color: "primary",
                                          icon: "",
                                          "x-small": "",
                                          "aria-label": _vm.$t(
                                            "editAlternateDayLabel"
                                          ),
                                        },
                                        on: {
                                          click: function ($event) {
                                            $event.stopPropagation()
                                            return _vm.editAlternateDayModalShow(
                                              item
                                            )
                                          },
                                          keyup: [
                                            function ($event) {
                                              if (
                                                !$event.type.indexOf("key") &&
                                                _vm._k(
                                                  $event.keyCode,
                                                  "enter",
                                                  13,
                                                  $event.key,
                                                  "Enter"
                                                )
                                              )
                                                return null
                                              $event.stopPropagation()
                                              return _vm.editAlternateDayModalShow(
                                                item
                                              )
                                            },
                                            function ($event) {
                                              if (
                                                !$event.type.indexOf("key") &&
                                                _vm._k(
                                                  $event.keyCode,
                                                  "right",
                                                  39,
                                                  $event.key,
                                                  ["Right", "ArrowRight"]
                                                )
                                              )
                                                return null
                                              if (
                                                "button" in $event &&
                                                $event.button !== 2
                                              )
                                                return null
                                              return _vm.focusDeleteButton.apply(
                                                null,
                                                arguments
                                              )
                                            },
                                            function ($event) {
                                              if (
                                                !$event.type.indexOf("key") &&
                                                _vm._k(
                                                  $event.keyCode,
                                                  "left",
                                                  37,
                                                  $event.key,
                                                  ["Left", "ArrowLeft"]
                                                )
                                              )
                                                return null
                                              if (
                                                "button" in $event &&
                                                $event.button !== 0
                                              )
                                                return null
                                              return _vm.focusDayList.apply(
                                                null,
                                                arguments
                                              )
                                            },
                                            function ($event) {
                                              if (
                                                !$event.type.indexOf("key") &&
                                                _vm._k(
                                                  $event.keyCode,
                                                  "up",
                                                  38,
                                                  $event.key,
                                                  ["Up", "ArrowUp"]
                                                )
                                              )
                                                return null
                                              return _vm.focusDayList.apply(
                                                null,
                                                arguments
                                              )
                                            },
                                            function ($event) {
                                              if (
                                                !$event.type.indexOf("key") &&
                                                _vm._k(
                                                  $event.keyCode,
                                                  "down",
                                                  40,
                                                  $event.key,
                                                  ["Down", "ArrowDown"]
                                                )
                                              )
                                                return null
                                              return _vm.focusDayList.apply(
                                                null,
                                                arguments
                                              )
                                            },
                                          ],
                                        },
                                      },
                                      [_c("v-icon", [_vm._v("fal fa-pencil")])],
                                      1
                                    )
                                  : _vm._e(),
                                item.value !== 0
                                  ? _c(
                                      "v-btn",
                                      {
                                        ref: "deleteButton" + i,
                                        refInFor: true,
                                        staticClass:
                                          "mx-2 menu-subitem no-focus-border",
                                        attrs: {
                                          color: "error",
                                          icon: "",
                                          "x-small": "",
                                          "aria-label": _vm.$t(
                                            "deleteAlternateDayLabel"
                                          ),
                                        },
                                        on: {
                                          click: function ($event) {
                                            $event.stopPropagation()
                                            return _vm.deleteAlternateDayModalShow(
                                              item
                                            )
                                          },
                                          keyup: [
                                            function ($event) {
                                              if (
                                                !$event.type.indexOf("key") &&
                                                _vm._k(
                                                  $event.keyCode,
                                                  "enter",
                                                  13,
                                                  $event.key,
                                                  "Enter"
                                                )
                                              )
                                                return null
                                              $event.stopPropagation()
                                              return _vm.deleteAlternateDayModalShow(
                                                item
                                              )
                                            },
                                            function ($event) {
                                              if (
                                                !$event.type.indexOf("key") &&
                                                _vm._k(
                                                  $event.keyCode,
                                                  "left",
                                                  37,
                                                  $event.key,
                                                  ["Left", "ArrowLeft"]
                                                )
                                              )
                                                return null
                                              if (
                                                "button" in $event &&
                                                $event.button !== 0
                                              )
                                                return null
                                              return _vm.focusEditButton.apply(
                                                null,
                                                arguments
                                              )
                                            },
                                            function ($event) {
                                              if (
                                                !$event.type.indexOf("key") &&
                                                _vm._k(
                                                  $event.keyCode,
                                                  "up",
                                                  38,
                                                  $event.key,
                                                  ["Up", "ArrowUp"]
                                                )
                                              )
                                                return null
                                              return _vm.focusDayList.apply(
                                                null,
                                                arguments
                                              )
                                            },
                                            function ($event) {
                                              if (
                                                !$event.type.indexOf("key") &&
                                                _vm._k(
                                                  $event.keyCode,
                                                  "down",
                                                  40,
                                                  $event.key,
                                                  ["Down", "ArrowDown"]
                                                )
                                              )
                                                return null
                                              return _vm.focusDayList.apply(
                                                null,
                                                arguments
                                              )
                                            },
                                          ],
                                        },
                                      },
                                      [
                                        _c("v-icon", [
                                          _vm._v("fal fa-trash-alt"),
                                        ]),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                              ],
                              1
                            ),
                          ],
                          1
                        )
                      }),
                    ],
                    2
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c("v-spacer"),
          !_vm.editMode
            ? _c(
                "pb-btn",
                {
                  staticClass: "my-1 mr-6",
                  attrs: {
                    color: "primary",
                    disabled: _vm.fullEditorEditMode,
                    text: "",
                    outlined: "",
                  },
                  on: {
                    click: function ($event) {
                      $event.preventDefault()
                      $event.stopPropagation()
                      return _vm.quickAddClass.apply(null, arguments)
                    },
                  },
                },
                [
                  _c("v-icon", { attrs: { small: "" } }, [
                    _vm._v("fal fa-plus"),
                  ]),
                  _c("span", [_vm._v(_vm._s(_vm.$t("addClassLabel")))]),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }