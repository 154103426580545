



























































































import { Component, Watch } from 'vue-property-decorator';
import BaseToolbar from '@/components/core/BaseToolbar.vue';
import { namespace } from 'vuex-class';
import ClassesMixin from '@/mixins/classes-mixin';
import moment from 'moment';
import { VForm } from 'vuetify/lib';
import ClassesToolbar from './ClassesToolbar.vue';

const classes = namespace('classes');
const settings = namespace('settings');

@Component({
  mixins: [ClassesMixin],
  components: {
    BaseToolbar
  }
})
export default class ClassesBottomToolbar extends ClassesToolbar {
}
